<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实验报告(查看)</div>
      <!-- <el-input
        class="elinput"
        size="small"
        v-model="searchvalue"
        placeholder="请输入内容"
        clearable
      >
        <i class="el-icon-search el-icon_search" slot="suffix"> </i>
      </el-input> -->
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="headcontain">
        <!-- <div class="lefttitle">查看实验报告</div> -->
        <div class="returnbt" @click="returnbt">返回</div>
      </div>
      <div class="containtwo">
        <div class="contain">
          <div class="title">水土保持实验报告</div>
          <div class="namecontain">
            <div class="left">
              <div class="text">王宝利:</div>
              <div class="text">02554488988989</div>
            </div>
            <div class="right">
              <div>文法学院/2021级</div>
            </div>
          </div>
          <div class="itemcontain">
            <div class="item">
              <div class="text">实验课程:</div>
              <div class="text">临床医学</div>
            </div>
            <div class="item">
              <div class="text">实验项目:</div>
              <div class="text">临床医学</div>
            </div>
            <div class="item">
              <div class="text">指导老师:</div>
              <div class="text">王老师</div>
            </div>
          </div>
        </div>
      </div>
      <div class="containthree">
        <div class="contain">
          <div class="title">实验报告:</div>
          <div class="itemtitle">1.前言</div>
          <div class="text">内容介绍。。。</div>
          <div class="appendix">
            <div class="text">附件:</div>
            <div class="text">文件.pdf</div>
            <div class="imagecontain">
              <el-image class="elimage" :src="icon2" />
              <el-image class="elimage" :src="icon3" />
            </div>
          </div>
        </div>
      </div>
      <div class="containfour">
        <div class="contain">
          <div class="itemtitle">评分:80</div>
          <div class="itemtitle">评语:数据需要完善</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mainpage",
  components: {},
  data() {
    return {
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-幻灯片.png"),
      icon2: require("@/assets/查看.png"),
      icon3: require("@/assets/下载.png"),
    };
  },
  computed: {},
  methods: {
    returnbt() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 0,
          componentId: "Virtualsimulationexperiment",
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-title {
      font-size: 20px;

      font-weight: 400;
      color: #288add;
      user-select: none;
      white-space: nowrap;
    }
    .elinput {
      width: 300px;
      ::v-deep .el-input__suffix {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    // height: 1200px;
    background: #f7f7f7;
    border-radius: 4px;
    padding: 30px;

    margin-bottom: 20px;
    .headcontain {
      display: flex;
      justify-content: flex-end;
      align-content: flex-end;
      margin-bottom: 20px;
      .lefttitle {
        font-size: 25px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #222222;
        user-select: none;
      }
      .returnbt {
        width: 88px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        background: #26b5ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
      }
    }
    .containtwo {
      background: #ffffff;
      height: 140px;
      padding: 30px;
      padding-left: 60px;
      .contain {
        .title {
          font-size: 25px;
          font-weight: 400;
          color: #222222;
        }
        .namecontain {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .text {
              margin-right: 10px;
              font-size: 16px;
              font-weight: 400;
              color: #222222;
            }
          }
          .right {
          }
        }
        .itemcontain {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .text {
              margin-right: 10px;
              font-size: 16px;
              font-weight: 400;
              color: #222222;
            }
          }
        }
      }
    }

    .containthree {
      margin-top: 20px;
      background: #ffffff;
      // height: 640px;
      padding: 30px;
      padding-left: 60px;
      .contain {
        .title {
          font-size: 20px;
          font-weight: 400;
          color: #222222;
        }
        .itemtitle {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #222222;
        }
        .text {
          font-size: 16px;
          font-weight: 400;
          color: #222222;
        }
        .appendix {
          margin-top: 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .text {
            margin-right: 10px;
            font-size: 16px;
            font-weight: 400;
            color: #222222;
          }
          .imagecontain {
            margin-left: 40px;
          }
        }
      }
    }
    .containfour {
      margin-top: 20px;
      background: #ffffff;
      // height: 640px;
      padding: 30px;
      padding-left: 60px;
      .contain {
        .itemtitle {
          margin-top: 15px;
          font-size: 16px;
          font-weight: 400;
          color: #222222;
        }
        .text {
          font-size: 16px;
          font-weight: 400;
          color: #222222;
        }
      }
    }
  }
}
</style>
